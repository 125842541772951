import Lightbox from 'bs5-lightbox';

function runMultiCarousel(items, columns) {
  items.forEach((el) => {
    const minPerSlide = columns
    let next = el.nextElementSibling
    for (var i=1; i<minPerSlide; i++) {
      if (!next) {
        // wrap carousel by using first child
        next = items[0]
      }
      let cloneChild = next.cloneNode(true)
      el.appendChild(cloneChild.children[0])
      next = next.nextElementSibling
    }
  })
}

export default {
  init() {
    // JavaScript to be fired on all pages
    document.querySelector('#main-menu-toggle').addEventListener('click', function(){
      let toggle = document.querySelector('#main-menu-toggle');
      let menu = document.querySelector('#collapseWidthMenu');
      toggle.classList.toggle('close');
      menu.classList.toggle('close-menu');
    })

    let carousels = document.querySelectorAll('.carousel-multi')
    carousels.forEach((el) => {
      let carouselItems = document.querySelectorAll('#'+el.id+' .carousel-item')
      let carouselCols = el.dataset.cols;
      runMultiCarousel(carouselItems, carouselCols)
    })

    let copyEls = document.querySelectorAll('.clipboardCopyText');
    copyEls.forEach(copyEl => {
      copyEl.addEventListener('click', function () {
        let copyText = this.dataset.copy;
        navigator.clipboard.writeText(copyText);
        this.classList.add('copy-active');
        setTimeout(() => { 
          this.classList.remove('copy-active');
        }, 1000);
      });
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    document.querySelectorAll('.tend-lightbox').forEach(el => el.addEventListener('click', Lightbox.initialize));
  },
};
